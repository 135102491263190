import './download-app.styles.scss';
import { Link } from 'react-router-dom';
import {ReactComponent as OvalSvg} from '../../assets/Oval.svg';
import {ReactComponent as WaveSvg} from '../../assets/Wave.svg';
const DownloadApp = () => {
    return (
        <div className='download-app-wrapper'>
            <div>
                <h1>
                    Shopping at <br /> Wholesale Prices.
                </h1>
                 <p>Download Massoud app and shop at wholesale prices.</p>
                <div className='download-buttons-holder'>
                    <Link to='/'><img src={require('../../assets/button-app.png')} width='150px' alt="" srcSet="" /></Link>
                    <Link to='/'><img src={require('../../assets/button-play.png')} width='150px' alt="" srcSet="" /></Link>
                </div>
            </div>
            <OvalSvg className='oval'/>
          
            <WaveSvg className='waves'/>
             <img className='phone' src={require('../../assets/iphone.png')}  alt="" srcSet="" />
        </div>
    )
}

export default DownloadApp;