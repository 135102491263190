import { useParams , useNavigate} from "react-router-dom";
import { useSelector } from "react-redux";
import { selectOrdersMap } from "../../store/categories/category.selector";
import './order.styles.scss'
const Order = () => {
    let {id} = useParams();
    console.log(id);
    const orders = useSelector(selectOrdersMap);
    const navigate = useNavigate();
     const orderDetails = orders.find((produc, idx) => produc.id === id);
     console.log(orderDetails);
    return (
        <div className="order-details-wrapper">
            <span>Delivered</span>
        <h2>Order No: #778456</h2>
        <div className="details-holder">
         <h5>Order Date</h5>
         <h3>Jul 07, 2021</h3>
         {orderDetails.cartItems.map((item) => 
         <div className="order-item-holder">
            <div className="name-holder">
            <img src={item.imageUrl} alt="" srcset="" width='60px' />
           <p>{item.name}</p> 
           <p className="quant">x{item.quantity}</p> 
            </div>
            <p className="price">${item.price}</p>
            
        </div>
         )}
         <div className="order-item-holder">
        <p className="price">{orderDetails.cartItems.length} products</p>
         <p className="price">${orderDetails.cartTotal}</p>
         </div>
       <button className="track-button" onClick={() => navigate(`../../orders/track/${orderDetails.id}`)}>Track your order</button>
        </div>
      

        </div>
        
    )
}


export default Order;