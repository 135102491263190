import './sign-up-offer.styles.scss'

const SignUpOffer = () => {
    return (
        <div className="sign-up-offre-wrapper">
            <div>
                <h1>Get 10% off your next order</h1>
                <h4>Sign up for our exclusive offers and newsletter.</h4>
            </div>
            <div>
                <form>
                    <input placeholder="Email Address"/>
                    <button>Send</button>
                </form>
            </div>
        </div>
    )
}


export default SignUpOffer;