import { Routes, Route } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";

import './order-auth.styles.scss';
import SignUpOrder from "../../components/order-auth/sign-up-order.component";
import SignInOrder from "../../components/order-auth/sign-in-order.component";

import Footer from "../../components/footer/footer.component";
import './order-auth.styles.scss'
const OrderAuth = () => {

    let activeStyle = {
    color : '#C47C5A'
   }

    return (
        <>
        
            <div className="order-auth-wrapper">
           <div className="auth-white-box">
            <div className="auth-navbar" >
            <NavLink to='/auth-order/sign-in' style={({ isActive }) =>
              isActive ? activeStyle : undefined
            }>Sign In</NavLink>
            <NavLink to='/auth-order/sign-up' style={({ isActive }) =>
              isActive ? activeStyle : undefined
            }>New user ?</NavLink>
           </div>
        <Routes>

          <Route index path='/sign-in' element={<SignInOrder/>}/> 
          <Route path='/sign-up' element={<SignUpOrder/>}/>  
         
        </Routes>
                <Link to='/checkout'><p>Continue as a guest</p></Link>
                
            </div>
            
        </div>
        
       
        
            

           
           
                 <Footer/>
             
        </>
       
    )
}


export default OrderAuth;