import { selectAddressesMap } from "../../store/categories/category.selector";
import {ReactComponent as TrashIcon} from '../../assets/trash.svg';
import {ReactComponent as EditIcon} from '../../assets/edit.svg';
import { useSelector, useDispatch } from "react-redux";
import { selectCurrentUser } from "../../store/user/user.selector";
import { useState } from "react";
import { v4 } from "uuid";
import { addAddress } from "../../utils/firebase/firebase.utils";
import { getAddresses, updateAddress, deleteAddress } from "../../utils/firebase/firebase.utils";
import { setAddressesMap } from "../../store/categories/category.action";
import './addresses.styles.scss'
const defaultFormFields = {
   
    phoneNumber:'',
    address:'',
    streetAddress:"",
    title:'',
    id:''
}
const Addresses = () => {
    const dispatch = useDispatch();
    const addresses = useSelector(selectAddressesMap);
    const user = useSelector(selectCurrentUser);
    const [showAddressForm, setShowAddressForm] = useState(false);
    const [showAddressEditForm, setShowAddressEditForm] = useState(false);
    const [formFields, setFormFields] = useState(defaultFormFields)
    const {phoneNumber,address,streetAddress,title,id} = formFields;
    const getAddressesMap = async () => {
      const categoriesArray = await getAddresses();
      dispatch(setAddressesMap(categoriesArray));
    };
   
     const handleChange = (event) => {
        const {name , value} = event.target;
        setFormFields({...formFields, [name]: value})
    }
    const handleClick = () => {
        setShowAddressForm(!showAddressForm);
    }
  
    let userAddresses;
   if(user){
    userAddresses = addresses.filter((produc, idx) => produc.uid === user.uid);
   } else {
    userAddresses = []
   }
   const resetFormFields = () => {
        setFormFields(defaultFormFields);
      };
      const handleSubmit = async (event) => {
        const id = v4();
    event.preventDefault();
   
    try {
       await addAddress(address,phoneNumber,streetAddress,title, user.uid,id)
       await getAddressesMap();
        setShowAddressForm(!showAddressForm) 
       resetFormFields();               
       
    } catch(error) {
        console.log('Adding address error', error)
    }
}
        const handleEditShow = (title,phoneNumber,address,streetAddress) => {
    setShowAddressEditForm(!showAddressEditForm);
    setFormFields({title:title,phoneNumber:phoneNumber,address:address,streetAddress:streetAddress})
}

     const handleUpdateAddress = async (event) => {
        
    event.preventDefault();
   
    try {
       await updateAddress(address, phoneNumber,streetAddress, title , id);
       await getAddressesMap();
         setShowAddressEditForm(false) 
       resetFormFields();               
       
    } catch(error) {
        console.log('Adding address error', error)
    }
}

 
    return (
        <div>
            <div className='addresses-wrapper' >
                {
                    !showAddressForm && !showAddressEditForm ?  <div  className='personal-info-box-holder'>
            {
                userAddresses.length > 0 ? 
                userAddresses.filter((produc, idx) => produc.uid === user.uid).map((item) => (
                    <div  className='value-icon-box'>
                        <div className='value-wrapper'>
                            <span>{item.title}</span> 
                            <p>{item.address}</p>
                        </div> 
                        <div className="buttons-holder">
                        <TrashIcon onClick={ async () => {await deleteAddress(item.id); getAddressesMap()}}/>
                        <EditIcon onClick={()=> {setShowAddressEditForm(!showAddressEditForm); setShowAddressForm(false); setFormFields({title:item.title,phoneNumber:item.phoneNumber,address:item.address,streetAddress:item.streetAddress,id:item.id})}}/>
                    </div>
                        </div>
                )) : <h3>No Addresses Yet!</h3>
            }
            </div> : null
                }
           
            {
                showAddressForm ? 
                <form onSubmit={handleSubmit}>
                <input type="text" placeholder='Title' name="title" value={title} onChange={handleChange} />
                <input type="number" placeholder='Phone Number' name="phoneNumber" value={phoneNumber} onChange={handleChange} />
                <input type="text" placeholder='Address' name="address" value={address} onChange={handleChange} />
                <input type="text" placeholder='Street address, Apt, Floor, Unit' name="streetAddress" value={streetAddress} onChange={handleChange} />
                <button type="submit">Add Address</button>
                </form> : null
            }
             {
                showAddressEditForm ? 
                <form onSubmit={handleUpdateAddress}>
                <input type="text" placeholder='Title' name="title" value={title} onChange={handleChange} />
                <input type="number" placeholder='Phone Number' name="phoneNumber" value={phoneNumber} onChange={handleChange} />
                <input type="text" placeholder='Address' name="address" value={address} onChange={handleChange} />
                <input type="text" placeholder='Street address, Apt, Floor, Unit' name="streetAddress" value={streetAddress} onChange={handleChange} />
                <button type="submit">Update Address</button>
                </form> : null
            }

           
          {
            !showAddressForm && !showAddressEditForm ? <button onClick={handleClick}>Add New Address</button> : null
          } 
        </div>
          
        </div>
    )
}


export default Addresses;