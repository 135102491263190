import CategoryBox from "../../components/category-box/category-box.component"
import { Routes, Route, Outlet } from 'react-router-dom';
import Footer from "../../components/footer/footer.component";
import Category from "../../components/category/category-component";
const CategoriesScreen = () => {
    return (
        <>

          <Outlet/>
               
          <CategoryBox />
        <Routes>
      <Route path=':name' element={<Category />}/>
      </Routes>
   
      <Footer/>
       
      </>
    )
} 

export default CategoriesScreen;