import { useSelector } from "react-redux";
import { selectProductsMap } from "../../store/categories/category.selector";
import ProductCard from "../product-card/product-card.component";
import './promoted-items.styles.scss'
const PromotedItems = () => {
    const products = useSelector(selectProductsMap);
    const promotedProducts = products.filter((produc, idx) => produc.promoted === true );
    return (
        <div className="promoted-items-wrapper">
            <h1>Promotions</h1>
            <div className="promoted-items-map">
            {
                promotedProducts.map((product) => {
                    return <ProductCard product={product}/>
                })
            }
            </div>
        </div>
    )
}

export default PromotedItems;