import { signInWithGooglePopup , createUserDocumentFromAuth } from "../../utils/firebase/firebase.utils";
import SignUpForm from "../../components/sign-up-form/sign-up-form.component";
import SignInForm from "../../components/sign-in-form/sign-in-form.component";
import { Link , NavLink} from "react-router-dom";
import { Route , Routes } from 'react-router-dom';
import './authentication.styles.scss';
const Authentication = () => {
   let activeStyle = {
    color : '#C47C5A'
   }
    return (
        <div className="auth-wrapper">
            <Link to="/">
            <img width='75px' src={require('../../assets/logo-white.png')} alt="" srcSet="" />
            </Link>
            <div className="auth-white-box">
            <div className="auth-navbar" >

            <NavLink to='/auth/sign-in' style={({ isActive }) =>
              isActive ? activeStyle : undefined
            }>Sign In</NavLink>
            <NavLink to='/auth/sign-up' style={({ isActive }) =>
              isActive ? activeStyle : undefined
            }>New user ?</NavLink>
            
            
            </div>

            
           
       
       
        <Routes>
          <Route index path='/sign-in' element={<SignInForm/>}/> 
          <Route path='/sign-up' element={<SignUpForm/>}/>  
                     
                </Routes>
                </div>
        </div>
    )
}

export default Authentication;