import { createContext , useReducer } from "react";
import { createAction } from "../utils/reducer/reducer.utils";




const addCartItem = (cartItems, productToAdd) => {
    const existingCartItem = cartItems.find((cartItem) => cartItem.id === productToAdd.id)
   

    if(existingCartItem){
        return cartItems.map((cartItem) => cartItem.id === productToAdd.id ? 
        {...cartItem, quantity: cartItem.quantity + 1} 
        : cartItem
        )
    }
   
   
   
   
    return [ ...cartItems, {...productToAdd, quantity: 1}];
}

    const removeCartItem = (cartItems , cartItemToRemove) => {
        const existingCartItem = cartItems.find((cartItem) => cartItem.id === cartItemToRemove.id);
        
        if (existingCartItem.quantity === 1) {
            return cartItems.filter(cartItem => cartItem.id !== cartItemToRemove.id);
        }

        return cartItems.map((cartItem) => cartItem.id === cartItemToRemove.id ? 
        {...cartItem, quantity: cartItem.quantity - 1} 
        : cartItem
        )
    }

    const clearCartItem = (cartItems, cartItemToClear ) => {
        return cartItems.filter(cartItem => cartItem.id !== cartItemToClear.id);
    }

export const CartContext = createContext({
    isCartOpen: false,
    setIsCartOpen : () => {},
    cartItems: [],
    addItemToCart : () => {},
    removeItemFromCart : () => {},
    clearItemFromCart: () => {},
    cartCount: 0,
    cartTotal : 0
})

const CART_ACTION_TYPES = {
    SET_CART_ITEMS: 'SET_CART_ITEMS',
    SET_IS_CART_OPEN: 'SET_IS_CART_OPEN',
    CLEAR_CART_ITEMS : 'CLEAR_CART_ITEMS',
    ORDER_AGAIN : 'ORDER_AGAIN'
}


const INITIAL_STATE = {
    isCartOpen: false,
    cartItems :[],
    cartCount : 0,
    cartTotal: 0,

}

const cartReducer = (state,action) => {
    const {type,payload} = action;

    switch(type) {
        case CART_ACTION_TYPES.SET_CART_ITEMS :
            return {
                ...state,
                ...payload,
            }

        case CART_ACTION_TYPES.SET_IS_CART_OPEN : 
        return {
            ...state,
            isCartOpen: payload,
        }
        case CART_ACTION_TYPES.CLEAR_CART_ITEMS :
            return {
                
                cartItems:[],
                cartTotal: 0,
                cartCount:0,
                isCartOpen: false

            }
        case CART_ACTION_TYPES.ORDER_AGAIN :
            return {
                catItems: payload,
                cartTotal:payload,
                cartCount: payload,
                isCartOpen: true
            }
        



        default :
            throw new Error(`Unhandled type of ${type} is cartReducer`)
    }
}



export const CartProvider = ({children}) => {
   /* const [isCartOpen ,setIsCartOpen] = useState(false);
    const [cartItems, setCartItems] = useState([]);
    const [ cartCount , setCartCount] = useState(0);
    const [cartTotal , setCartTotal] = useState(0);
    useEffect(() => {
        const newCartCount = cartItems.reduce((total,cartItem) => total + cartItem.quantity,0)
        setCartCount(newCartCount);
    },[cartItems])
   
    useEffect(() => {
        const newCartTotal = cartItems.reduce((total,cartItem) => total + cartItem.quantity * cartItem.price ,0)
        setCartTotal(newCartTotal);
    },[cartItems]) */

    const [{cartItems,isCartOpen,cartCount,cartTotal},dispatch] = useReducer(cartReducer,INITIAL_STATE);

    const updateCartItemsReducer = (newCartItems) => {
        const newCartCount = newCartItems.reduce((total,cartItem) => total + cartItem.quantity,0)

        const newCartTotal = newCartItems.reduce((total,cartItem) => total + cartItem.quantity * cartItem.price ,0)
         
        dispatch(
            createAction(CART_ACTION_TYPES.SET_CART_ITEMS, {
                cartItems: newCartItems, 
                cartTotal: newCartTotal, 
                cartCount: newCartCount})
           )

    }


    const addItemToCart = (productToAdd) => {
      const newCartItems =   addCartItem(cartItems,productToAdd);
      updateCartItemsReducer(newCartItems);
    }
    const removeItemFromCart = (cartItemToRemove) => {
    const newCartItems =  removeCartItem(cartItems,cartItemToRemove);
    updateCartItemsReducer(newCartItems);
    }
    const clearItemFromCart = (cartItemToRemove) => {
        const newCartItems =  clearCartItem(cartItems,cartItemToRemove);
        updateCartItemsReducer(newCartItems);
    }

    const setIsCartOpen = (bool) => {
        dispatch(
            createAction(CART_ACTION_TYPES.SET_IS_CART_OPEN, bool)
            )
    }
    const clearCartItems = () => {
        dispatch(
            createAction(CART_ACTION_TYPES.CLEAR_CART_ITEMS)
        )
    }
    const orderAgain = (cartItems) => {
        updateCartItemsReducer(cartItems)
    }
    const value = {isCartOpen, setIsCartOpen , addItemToCart , cartItems, cartCount,removeItemFromCart ,clearItemFromCart , cartTotal,clearCartItems,orderAgain}

    return (
        <CartContext.Provider value={value}>{children} </CartContext.Provider>
    )
}