import './personal-info.styles.scss';
import {ReactComponent as UserIcon} from '../../assets/User.svg';
import { selectCurrentUser } from '../../store/user/user.selector';
import { selectUsersMap } from '../../store/categories/category.selector';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef , useState } from 'react';
import { updatePersonalInfo ,signOutUser} from '../../utils/firebase/firebase.utils';
import { getUsers } from '../../utils/firebase/firebase.utils';
import { setUsersMap } from '../../store/categories/category.action';

const PersonalInfo = ({clicking}) => {
    const user = useSelector(selectCurrentUser);
    const userInfo = useSelector(selectUsersMap);
    const navigate = useNavigate();
    const [isDisabled , setIsDisabled] = useState(true);
    const dispatch = useDispatch();
  const nameInput = useRef(null);
  const getUsersMap = async () => {
      const categoriesArrayy = await getUsers();
      dispatch(setUsersMap(categoriesArrayy));
   
    };
    const handleLogOut = async () => {
        await signOutUser();
        await clicking()
        navigate('../');

    }

   const handleClick = async () => {
    await setIsDisabled(!isDisabled);
    await setUpdateButton(!updateButton)
     nameInput.current.focus();
   
  };


      
    let userPersonalInfo;
     if(user){
        
    userPersonalInfo = userInfo.find((produc, idx) => produc.id === user.uid);
   } else {
    userPersonalInfo = undefined
   }
 const defaultFormFields = {
   
    displayName: user ? userPersonalInfo.displayName : '',
    phoneNumber: user ? userPersonalInfo.phoneNumber : '',
    
}
const [formField, setFormField] = useState(defaultFormFields);
const [updateButton, setUpdateButton] = useState(false);
const [showElement, setShowelement] = useState(false);
const {displayName, phoneNumber} = formField;

const handleChange = (event) => {
    
        const {name , value} = event.target;
        setFormField({...formField, [name]: value});
    }
  const handleUpdate = async (event) => {
   event.preventDefault();

    try {
        await updatePersonalInfo(displayName,phoneNumber,userPersonalInfo.id);
        await setIsDisabled(!isDisabled);
        await setUpdateButton(!updateButton);
        await setShowelement(!showElement);
        getUsersMap();
        setTimeout(function() {
      setShowelement(false)
         }, 3000);
    } catch (error) {
        console.log(error)
    }
   
  }
    return (
        <div className='personal-info-wrapper'>
           
                <form className='personal-info-box-holder-form'  onSubmit={handleUpdate}>
                    <div className='personal-info-box-holder'>
                <div className='input-holder'>
                  
                <label>Name</label>
                
                <input  type="text" ref={nameInput} disabled={isDisabled} onChange={handleChange} name='displayName' value={displayName}/>
                </div>
                <div className='input-holder'>
                <label htmlFor=""> Phone Number</label>
                <input   type="number" disabled={isDisabled} onChange={handleChange} name='phoneNumber'  value={phoneNumber}/>
                </div>
                <div className='input-holder'>
                <label htmlFor=""> Email</label>
                <input type="text" disabled value={user ? userPersonalInfo.email : ''}/>
                
                </div>
                </div>
                {
                    updateButton ? <button className='button-upd' type='submit'>Update</button> : null
                }
                {
                    showElement ? <p>Your information has been updated!</p> : null
                }
                 
                </form>
                
            
           {
            !updateButton ? <div className='three-buttons'> <button onClick={() => {navigate('../orders'); clicking() }}>Recent Orders</button> <button onClick={handleClick}>Edit</button> <button onClick={handleLogOut}>Log Out</button>  </div> : null
           }
           
          
        </div>
    )
}


export default PersonalInfo;