import { useSelector } from 'react-redux';
import { selectCategoriesMap } from '../../store/categories/category.selector';
import './category-box.styles.scss';
import { Link } from 'react-router-dom';
import { Outlet , Route , Routes } from 'react-router-dom';
import Category from '../category/category-component';
import { useLocation } from 'react-router-dom';
const CategoryBox = () => {
    const categories = useSelector(selectCategoriesMap);
    const location = useLocation();
    if (location.pathname === '/categories' || location.pathname === '/home'){
         return (
        <>
      
     
          <div className='categories-box-holder'>
              
            <h1>Categories</h1>
            <div className='categories-map'>
              
                   {
            categories.map((item) => (
                <Link to={`../categories/${item.name}`} className='category-box'>
                    <h5> {item.name} </h5>
                    <img src={item.imageUrl} alt={item.imageUrl} width="50px"srcSet="" />
                  
                    </Link>
            ))}
               
            </div>
             
           
        </div>
    
      
        </>
    )
    } else {
        return <></>
    }
   
}

export default CategoryBox;