import './product-card.styles.scss';
import { useContext } from 'react';
import { CartContext } from '../../contexts/cart.context';
import { useNavigate } from 'react-router-dom';
import {ReactComponent as HeartIcon} from '../../assets/Heart.svg';

import { useState } from 'react';
const ProductCard = ({product}) => {

    const [color ,setColor] = useState('transparent');
    const {name,price, imageUrl,id} = product;
    const priceRate = 23000;
    const {addItemToCart, setIsCartOpen, isCartOpen} = useContext(CartContext);
    const addProductToCart = async () => {
        await addItemToCart(product); 
        setIsCartOpen(true);
    }
 const navigate = useNavigate();

    const goToCheckoutHandler = () => {
        navigate(`../../products/${id}`);
    }

 

    return (
        <div  className='product-card-container'>
           
            <div className='product-card-img' onClick={goToCheckoutHandler}>
            <img src={imageUrl} alt={name} />
            <span className='name'>{name}</span>
            <span className='price'> LBP {price * priceRate} </span>
            </div>
            <button className='add-product' onClick={addProductToCart}>+</button>
        </div>
    )
}

export default ProductCard