import { Link } from 'react-router-dom';
import {ReactComponent as FacebookIcon} from '../../assets/facebook.svg';
import {ReactComponent as TwitterIcon} from '../../assets/twitter.svg';
import './footer.styles.scss';
const Footer = () => {
    return (
        <div className="footer-wrapper">
            <div className='l2'>
                <img src={require('../../assets/logo-white.png')} width='65px' alt="" srcSet="" />
                <h2>Shopping at <br /> Wholesale Prices.</h2>
            </div>
            <div>
                <h4>
                    Company
                </h4>
                <Link to='/'>About us</Link>
                <div className='social-media-holder'>
                    <Link to='https://www.facebook.com'><FacebookIcon/></Link>
                    <Link to='https://www.instagram.com'><TwitterIcon/></Link>
                </div>
               
            </div>
              <div>
                <h4>
                    Support
                </h4>
                <Link to='/'>Privacy Policy</Link>
                <Link to='/'>Terms & <br /> conditions</Link>
            </div>
            

        </div>
    )
}



export default Footer;