import Footer from "../../components/footer/footer.component";
import './add-addres.styles.scss';
import { addAddress } from "../../utils/firebase/firebase.utils";
import { useState,  useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector , useDispatch} from "react-redux";
import { selectCurrentUser } from "../../store/user/user.selector";
import { selectAddressesMap } from "../../store/categories/category.selector";
import { v4 } from 'uuid';
import { setUsersMap } from "../../store/categories/category.action";
import { getUsers } from "../../utils/firebase/firebase.utils";
const defaultFormFields = {
   
    phoneNumber:'',
    address:'',
    streetAddress:"",
    title:'',
}
const AddAddress = () => {
    let navigate = useNavigate();
       const dispatch = useDispatch();
  useEffect(() => {
    const getUsersMap = async () => {
      const categoriesArrayy = await getUsers();
      dispatch(setUsersMap(categoriesArrayy));
   
    };
    getUsersMap();
  },[])
    const [formFields, setFormFields] = useState(defaultFormFields)
    const {phoneNumber,address,streetAddress,title} = formFields;
    const user = useSelector(selectCurrentUser);
    const addresses = useSelector(selectAddressesMap);

    const addressesLength = addresses.filter((produc, idx) => produc.uid === user.uid);
    console.log(addressesLength)
    const handleChange = (event) => {
        const {name , value} = event.target;
        setFormFields({...formFields, [name]: value})
    }

    const handleSubmit = async (event) => {
        const id = v4();
    event.preventDefault();
   
    try {
       await addAddress(address,phoneNumber,streetAddress,title, user.uid,id)
                          
       await  navigate("../checkout");
        //window.location.reload(false); 
    } catch(error) {
        console.log('Adding address error', error)
    }
   
   
}

    return (
        <>
       
        <div className="add-address-order-wrapper">
           {
            addressesLength.length > 0 ?   <h3>Add Address</h3> :<h3>No Shipping Address Yet</h3>
           }
              
                <p>Please create an order address to order completion</p>
                 <form onSubmit={handleSubmit}>
                <input type="text" placeholder='Title' name="title" value={title} onChange={handleChange} />
                
                <input type="number" placeholder='Phone Number' name="phoneNumber" value={phoneNumber} onChange={handleChange} />
                <input type="text" placeholder='Address' name="address" value={address} onChange={handleChange} />
                <input type="text" placeholder='Street address, Apt, Floor, Unit' name="streetAddress" value={streetAddress} onChange={handleChange} />
              
                <button type="submit">Add Address</button>
                </form> 
        </div>
        <Footer/>
  </>
    )
}


export default AddAddress;