import { CATEGORIES_ACTION_TYPES } from "./category.types";

export const CATEGORIES_INITIAL_STATE = {
    categoriesMap: [],
    productsMap: [],
    addressesMap: [],
    ordersMap: [],
    usersMap:[]
}


export const categoriesReducer = (state=CATEGORIES_INITIAL_STATE, action={}) => {
    const {type , payload} = action;

    switch(type){
        case CATEGORIES_ACTION_TYPES.SET_CATEGORIES_MAP:
            return {...state, categoriesMap: payload};
        case CATEGORIES_ACTION_TYPES.SET_PRODUCTS_MAP:
            return {...state, productsMap: payload};
            case CATEGORIES_ACTION_TYPES.SET_ADDRESSES_MAP:
            return {...state, addressesMap: payload};
              case CATEGORIES_ACTION_TYPES.SET_ORDERS_MAP:
            return {...state, ordersMap: payload};
               case CATEGORIES_ACTION_TYPES.SET_USERS_MAP:
            return {...state, usersMap: payload};
         
        
        default:
            return state;

    }
}