import './checkout-item.styles.scss';
import { useContext } from 'react';
import { CartContext } from '../../contexts/cart.context';
import {ReactComponent as TrashIcon} from '../../assets/trash.svg';
const CheckoutItem = ({cartitem}) => {
    const {name, imageUrl , price , quantity } = cartitem;
    const {clearItemFromCart, addItemToCart , removeItemFromCart} = useContext(CartContext);
    const clearItemHandler = () => clearItemFromCart(cartitem);
    const addItemHandler = () => addItemToCart(cartitem);
    const removeItemHandler = () => removeItemFromCart(cartitem);
    return (
        <div className='checkout-item-container'>
            <div className='image-container'>
            <img src={imageUrl} alt={name} />
            </div>
            <div className='info-container'>
            <span className='name'>{name}</span>
            <div className='add-info-holder'>
                <span className='minus' onClick={removeItemHandler}> - </span>
                <span className='quantity'>{quantity}</span>
                <span className='plus' onClick={addItemHandler}> + </span>
            </div>
            </div>
            
            
            <span className='price'>{price * quantity}$</span>
           
            
            <div className='remove-button' onClick={clearItemHandler}><TrashIcon/></div>
        </div>
    )
};


export default CheckoutItem;