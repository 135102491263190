import { useSelector } from "react-redux"
import { useEffect, useContext } from "react";
import { CartContext } from "../../contexts/cart.context";
import { getOrders } from "../../utils/firebase/firebase.utils";
import { setOrdersMap } from "../../store/categories/category.action";
import { selectOrdersMap } from "../../store/categories/category.selector";
import { selectCurrentUser } from "../../store/user/user.selector";
import { useDispatch } from "react-redux";
import { Routes , Route , Outlet} from "react-router-dom";
import { useNavigate } from "react-router-dom";
import './orders.styles.scss';
const Orders = () => {
    const dispatch = useDispatch();
    const orders = useSelector(selectOrdersMap);
    const user = useSelector(selectCurrentUser);
    const {orderAgain,setIsCartOpen} = useContext(CartContext);
    const navigate = useNavigate();
   useEffect(() => {
        const getOrdersMap = async () => {
      const categoriesArray = await getOrders();
      dispatch(setOrdersMap(categoriesArray));
    };
getOrdersMap();
   },[]);


    return (
        <>
        <Outlet/>
        <div className="orders-wrapper">
            <h1>Orders</h1>
              { user ?
                orders.filter((produc, idx) => produc.userId === user.uid).map((item) => (
                    <div className="order-box-holder">
                        {item.cartItems.slice(0,1).map((item) => (
                            <div className="order-cart-item">
                                <img src={item.imageUrl} alt="" srcSet="" />
                                {item.name}
                                </div>
                        ))}
                         <button className="details-button" onClick={() =>    navigate(`../../orders/${item.id}`)}>More details</button>
                         <div className="cart-orders-info">
                            <p>{item.cartItems.length} products</p>
                            <p>${item.cartTotal}</p>
                         </div>
                       
                        <button onClick={ async () => {await orderAgain(item.cartItems); setIsCartOpen(true)}}>Order again</button>
                    </div>
                    
                )) : <></>
            }
        </div>
         
        
     
        </>
    )
}

export default Orders;