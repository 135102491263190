import {Outlet,Link} from 'react-router-dom';
import './navigation.styles.scss';
import { selectCurrentUser } from '../../store/user/user.selector';
import { CartContext } from '../../contexts/cart.context';
import { useContext ,useState} from 'react';
import { useSelector } from 'react-redux';
import {ReactComponent as ProfileIcon} from '../../assets/profile.svg';
import Profile from '../../components/profile/profile.component';
import CartIcon from '../../components/cart-icon/cart-icon.component';

import { useLocation , useNavigate} from 'react-router-dom';
import Checkout from '../checkout/checkout.component';

import { selectCategoriesMap , selectProductsMap} from '../../store/categories/category.selector';
const Navigation = () => {
        let navigate = useNavigate();
        const currentUser = useSelector(selectCurrentUser);
        const categories = useSelector(selectCategoriesMap);
        const products = useSelector(selectProductsMap);
        const {isCartOpen} = useContext(CartContext);
        const location = useLocation();
        const [search , setSearch] = useState("");
        const [profileScreen, SetProfileScreen] = useState(false);
       
        const [toggleSearch , setToggleSearch] = useState(false);
        const toggleProfile = () => {
         
            SetProfileScreen(!profileScreen)
        }
        const toggleFunc = async () => {
            await setSearch('');
            await setToggleSearch(!toggleSearch);
               
        }
        const happened = async  (e) => {
              setSearch(' ');
            navigate(`../search/${search}`)
            setToggleSearch(!toggleSearch);
        }
        const handleSignIn = () => {
            navigate('../auth/sign-in');
        }
    if(location.pathname === '/auth' || location.pathname === '/auth/sign-in' || location.pathname === '/auth/sign-up' ){
    return (
        <>
    <div></div>
    <Outlet/>
    </>
    )
} else {
 return (
    <>
    {
        profileScreen ? 
        <div  className='profile-dark-bg'>
            <Profile clicking={toggleProfile}/>
        </div>  : <></>
    }
    {toggleSearch || search.length > 0 ?  <div className='navbar-search-wrapper' onClick={toggleFunc}>
            <div className='search-box'>
                {
                    products.filter((produc, idx) => produc.brand.startsWith(search)).map((product) => (
                        <>
                        {search.length > 0 ?  <div onClick={()=>navigate(`../products/${product.id}`)}>
                            <h4>{product.name}</h4>
                            <img src={product.imageUrl} alt="" srcSet="" />
                        </div> : null }
                       
                        </>
                    ))
                  
                }
                <h4>Search For ... {search}</h4>
            </div>
        </div> : <></> }
    
       <div className='navbars-wrapper'>
        <div className='navbar'>
           <Link className='logo-container' onClick={() => window.scrollTo(0, 0)} to="../">
            <img src={require('../../assets/logo-white.png')} alt="" srcSet="" />
            </Link>
               <div className='form-cart-holder' style={{right: currentUser ? '50px' : "125px"}}>
                <form onSubmit={happened}>
                    <input  onChange={(e) => setSearch(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))} onClick={() => setToggleSearch(!toggleSearch)} value={search} className='search-nav-input' placeholder='What are you searching for?' />
                   
                </form>
                <CartIcon  />
                { isCartOpen && <Checkout/>}
            </div>

            <div className='nav-links-container'>
               
           {
                currentUser ? (
                    <span className='username-nav' onClick={()=> SetProfileScreen(!profileScreen)}><ProfileIcon/></span>
                   
                ) : (
                    <button onClick={handleSignIn} className='sign-in-button-nav'>Sign In</button>
                )
            }
          </div>
     
           
        </div>
        <div className='form-cart-holder-phone'>
                <form onSubmit={happened}>
                    <input  onChange={(e) => setSearch(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))} onClick={() => setToggleSearch(!toggleSearch)} value={search} className='search-nav-input' placeholder='What are you searching for?' />
                   
                </form>
               
                
            </div>
        <div className='categories-main-navbar'>
            {
            categories.map((item) => (
               <Link to={`/categories/${item.name}`}> {item.name}</Link>
            ))}
           
        </div>
        </div>
        <Outlet/>
     
        </>
    )
}

   
}

export default Navigation;