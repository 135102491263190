import { useState  } from "react";
import { useNavigate } from "react-router-dom";
import {createAuthUserWithEmailAndPassword, createUserDocumentFromAuth} from '../../utils/firebase/firebase.utils';

import './sign-up-form.styles.scss'
const defaultFormFields = {
    displayName:'',
    phoneNumber:'',
    email:'',
    password:"",
    confirmPassword:'',
}

const SignUpForm = () => {
  let navigate = useNavigate();
    const [formFields, setFormFields] = useState(defaultFormFields)
    const {displayName,email,phoneNumber,password,confirmPassword} = formFields;
    
    const resetFormFields = () => {
        setFormFields(defaultFormFields);
      };
const handleSubmit = async (event) => {
    event.preventDefault();
    if(password !== confirmPassword){
      alert("password do not match")
        return;
    }
    try {
        const {user} = await createAuthUserWithEmailAndPassword(email,password);
        
        await createUserDocumentFromAuth(user,displayName,phoneNumber);
        resetFormFields();
        navigate("../../", { replace: true });
        
        
    } catch(error) {
        console.log('user creation error', error)
    }
   
   
}

    const handleChange = (event) => {
        const {name , value} = event.target;
        setFormFields({...formFields, [name]: value})
    }

    return (
    <div className="sign-up-wrapper">
        
        <form onSubmit={handleSubmit}>
            <input placeholder="Display Name" type='text' required onChange={handleChange} name='displayName' value={displayName} />
          

           <input placeholder="Phone number" type="number" required onChange={handleChange} name="phoneNumber" value={phoneNumber} />
           

            <input placeholder="Email" type="email" required onChange={handleChange} name="email" value={email} />
           

           <input placeholder="Password" type="password" required onChange={handleChange} name="password" value={password} />
           <input placeholder="Confirm Password" type="password" required onChange={handleChange} name="confirmPassword" value={confirmPassword} />

            
            

            <button  type="submit">Sign up</button>
        </form>
    </div>       
    )
}

export default SignUpForm;