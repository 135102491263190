import { useSelector } from "react-redux";
import { selectOrdersMap } from "../../store/categories/category.selector";
import { useParams } from "react-router-dom";
import './order-tracking.styles.scss';
const OrderTracking = () => {
    const {orderId} = useParams();
    const orders = useSelector(selectOrdersMap);
    const orderDetails = orders.find((produc, idx) => produc.id === orderId);
    return (
        <div className="order-tracking-wrapper">
            <div className="lf">
                  <h3>Shipment Tracking</h3>
                  <div>July 11, 2022</div>
                  <img src={require('../../assets/Delivering.png')} alt="" srcset="" />
            </div>
          <div className="rl">
            <div>
                <p> <span className="track-circle filled"></span> Order Confirmed</p>
                <p className="date-text">8:00 Pm June 1, 2022</p>
            </div>
              <div>
                <p> <span className="track-circle"></span>Preparing Order</p>
                <p className="date-text">8:00 Pm June 1, 2022</p>
            </div>
              <div>
                <p> <span className="track-circle"></span>Picked up</p>
                <p className="date-text"> 8:00 Pm June 1, 2022</p>
            </div>
              <div>
                <p> <span className="track-circle"></span>Delivered</p>
                <p className="date-text">8:00 Pm June 1, 2022</p>
            </div>
          </div>
            
        </div>
    )
}

export default OrderTracking;