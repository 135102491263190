import './checkout.styles.scss'
import { CartContext } from '../../contexts/cart.context';
import { useContext } from 'react';
import CheckoutItem from '../../components/checkout-item/checkout-item.component';
import {ReactComponent as CloseIcon} from '../../assets/close.svg';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../store/user/user.selector';
import { selectAddressesMap } from '../../store/categories/category.selector';
import './checkout.styles.scss';
const Checkout = () => {
    const navigate = useNavigate();
    const address = useSelector(selectAddressesMap);
     const user = useSelector(selectCurrentUser);
   
    
    let userAddresses;
   if(user){
    userAddresses = address.filter((produc, idx) => produc.uid === user.uid);
   } else {
    userAddresses = []
   }
  
   const clearCart = () => {
    clearCartItems();
   }
   
    const navigateUser = () => {
        if(user && userAddresses.length > 0){
            navigate('../checkout');
            setIsCartOpen(false);
        } else if (!user) {
            navigate('../auth-order/sign-in');
            setIsCartOpen(false);
        } else if (user && userAddresses.length === 0) {
            navigate('../add-address');
            setIsCartOpen(false);
        }
     
    }
    const {cartItems,cartTotal,setIsCartOpen,clearCartItems} = useContext(CartContext);
    if(cartTotal === 0) {
        return (
            <div className='empty-card-wrapper'>
                 <div className='cart-close-btn-holder'><h2>Your Cart</h2> <CloseIcon  onClick={() => setIsCartOpen(false)}/></div>
                 <img src={require('../../assets/Empty-cart.png')} width='50%' alt="" srcSet="" />
                <h2>Your cart is empty</h2>
                <p>Looks like you havent made your choice yet. <br />
            Lets shop!</p>
            <Link to='../' onClick={() => setIsCartOpen(false)}> Continue Shopping</Link>
            </div>
        )
    }else {
return (
        <div className='checkout-screen-wrapper'>
            <div className='cart-wrapper'>
            <div className='title-button-holder'>
                <div className='cart-close-btn-holder'>
                    <h2>Your Cart</h2> 
                    <CloseIcon  onClick={() => setIsCartOpen(false)}/>
                </div>
                
               
            </div>
       
        <div className='cart-items-holder'>
        {
            cartItems.map((cartItem) => 
            { return(
              <CheckoutItem key={cartItem.id} cartitem={cartItem} />
            )}
            )
        }
      <Link to='../categories'><p>  Continue Shopping</p></Link>
        </div>
       
        </div>
        <div className='order-summary-wrapper'>
            <div className='order-summary-box'>
        
        
        
         <div className='details-info-total'>
            <p className='details-name'>Subtotal</p>
            <p className='details-price-total'>{cartTotal + 5}$</p>
         </div>
         <p className='tax'>Taxes and shipping calculated at checkout</p>
         <button onClick={navigateUser} className='checkout-button'>Checkout</button>
            </div>
           
        </div>
        </div>
        
    )
    }
    
}


export default Checkout;