import { useParams, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../store/user/user.selector";
import { selectAddressesMap } from "../../store/categories/category.selector";
import Footer from "../../components/footer/footer.component";
import { updateAddress } from "../../utils/firebase/firebase.utils";

const EditAddress = () => {
    const {addressId} = useParams();
    let navigate = useNavigate();
    
   
    const addresses = useSelector(selectAddressesMap);
    
    const addressesLength = addresses.find((produc, idx) => produc.id === addressId);
    const defaultFormFields = {
   
    phoneNumber: addressesLength ? addressesLength.phoneNumber : '',
    address: addressesLength ? addressesLength.address : '',
    streetAddress:addressesLength ? addressesLength.streetAddress : '',
    title:addressesLength ? addressesLength.title : '',
    
}
const [formFields, setFormFields] = useState(defaultFormFields)
    const {phoneNumber,address,streetAddress,title} = formFields;
     const handleChange = (event) => {
        const {name , value} = event.target;
        setFormFields({...formFields, [name]: value})
    }
     const handleUpdateAddress = async (event) => {
        
    event.preventDefault();
   
    try {
     
       await updateAddress(address, phoneNumber,streetAddress, title , addressId);
                  navigate('../checkout');
       
    } catch(error) {
        console.log('Updating address error', error)
    }
}
    return (
         <>
       
        <div className="add-address-order-wrapper">
           
                <h3>Edit Address</h3>
              
                 <form onSubmit={handleUpdateAddress} >
                <input type="text" placeholder='Title' name="title" value={title} onChange={handleChange} />
                
                <input type="number" placeholder='Phone Number' name="phoneNumber" value={phoneNumber} onChange={handleChange} />
                <input type="text" placeholder='Address' name="address" value={address} onChange={handleChange} />
                <input type="text" placeholder='Street address, Apt, Floor, Unit' name="streetAddress" value={streetAddress} onChange={handleChange} />
              
                <button type="submit">Update Address</button>
                </form> 
        </div>
        <Footer/>
  </>
        
    )
}


export default EditAddress;