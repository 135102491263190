import { useContext } from "react";
import ProductCard from "../../components/product-card/product-card.component";
import { ProductContext } from "../../contexts/products.context";
import { useSelector } from "react-redux";
import { selectCategoriesMap } from "../../store/categories/category.selector";
import { selectProductsMap } from "../../store/categories/category.selector";
const Shop = () => {
    const {products} = useContext(ProductContext);
    const realProducts = useSelector(selectProductsMap);
    return (
     <div>
        {
            realProducts.map((product) => (
                <ProductCard key={product.id} product={product} />
        ))}
      
       
     </div>
    )
}
export default Shop;