import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { selectCategoriesMap } from "../../store/categories/category.selector";
import { useParams } from "react-router-dom";
import { selectProductsMap } from "../../store/categories/category.selector";
import ProductCard from "../product-card/product-card.component";
import {ReactComponent as RightArrowIcon} from '../../assets/right-arrow.svg';
import './category.styles.scss';
import { Link } from "react-router-dom";
const Category = () => {
    const {name} = useParams();
    const products = useSelector(selectProductsMap);
    const categories = useSelector(selectCategoriesMap);
     const [brand , setBrand] = useState('');
    const categoryProducts = products.filter((produc, idx) => produc.category === name).filter((produc, idx) => produc.brand.startsWith(brand))
    console.log(categoryProducts);
    const [showAll , setShowAll] = useState(3);
    const [showBrandsPhone, setShowBrandsPhone] = useState(false);
     const [showAllBrands , setShowAllBrands] = useState(false);
       useEffect(() => {
  window.scrollTo(0, 0)
}, [name])
    const categoriesLength = categories.length;
    const handleShowAll = () => {
        if (showAll === 3){
         setShowAll(categoriesLength);
        } else {
               setShowAll(3);
        }
       
    }
       const handleShowAllBrands = () => {
       setShowAllBrands(!showAllBrands)
       
    }
    const handleBrandsHeets = () => {
        setBrand('Heets');
    }
    return (
          <>
         
        <div className="category-screen-wrapper">
            <div className="phone-brands" onClick={() => setShowBrandsPhone(!showBrandsPhone)}>
              <h3>Brands</h3>
              <span>></span>
              {showBrandsPhone ?  <div className="brand-phone">
                <h3 onClick={()=> setBrand('Marlboro')}>Marlboro</h3>
                <h3 onClick={()=> setBrand('Heets')}>Heets</h3>
                <h3>Toscano</h3>
                <h3>Xaikar</h3>
              </div> : null}
             
            </div>
             <div className="filters-box">
                <h4>Categories</h4>
            {categories.slice(0,showAll).map((category) => (
                 <Link to={`../../categories/${category.name}`} >{category.name} <RightArrowIcon/> </Link>
            ))}
            <button onClick={handleShowAll}>Show All</button>
                <h4>Brands</h4>
                <span onClick={()=> setBrand('Marlboro')} >Marlboro</span>
                <span onClick={handleBrandsHeets}>Heets</span>
               
                {
                    showAllBrands ? <> <span>Toscano</span>  <span>Xikar</span> </> : null
                }
               
                   <button onClick={handleShowAllBrands}>Show All</button>
                   <div className="filter-buttons">
                    <button>Apply</button>
                    <button>Clear</button>
                   </div>
             </div>
             <div className="product-categories-wrapper">
           <h1>{name}</h1>
             <div className="products-box">
                  
                {
             categoryProducts.map((product) => (
                <ProductCard key={product.id} product={product} />
        ))}
         
              </div>
              </div>
        </div>
        
     
      </>
    )
};

export default Category;