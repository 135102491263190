import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { signInAuthUserWithEmailAndPassword } from "../../utils/firebase/firebase.utils";
import { setCurrentUser } from "../../store/user/user.action";
import { useSelector } from "react-redux";
import { selectAddressesMap } from "../../store/categories/category.selector";
import { selectCurrentUser } from "../../store/user/user.selector";
const defaultFormFields = {
    email:'',
    password:"",
}
const SignInOrder = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [formFields, setFormFields] = useState(defaultFormFields)
    const {email,password} = formFields;

     const address = useSelector(selectAddressesMap);
    
    const user = useSelector(selectCurrentUser);
    const navigateUser = () => {
        if(user && address.length > 0){
            navigate('../checkout');
        } else if (!user) {
            navigate('../auth-order/sign-in');
        } else if (user && address.length === 0) {
            navigate('../add-address');
        }
     
    }
    const resetFormFields = () => {
        setFormFields(defaultFormFields);
      };
    const handleSubmit = async (event) => {
    event.preventDefault();
    
    try {
      
        const {user} = await signInAuthUserWithEmailAndPassword(email,password);
        
        resetFormFields();
        dispatch(setCurrentUser(user)); 
          if(user && address.length > 0){
            navigate('../../checkout');
        } else if (user && address.length === 0) {
            navigate('../add-address');
        }
        
        
    } catch(error) {
        if(error.code === "auth/user-not-found"){alert("user not found")}
        if(error.code === "auth/wrong-password"){alert("wrong password")}
        console.log('user sign in error', error)
    }
   
   
}
const handleChange = (event) => {
        const {name , value} = event.target;
        setFormFields({...formFields, [name]: value})
    }
    return (
    <div className="sign-in-wrapper">
        
        <form onSubmit={handleSubmit}>
            

           
            

            <input  label="Email" type="email" required onChange={handleChange} name="email" value={email} placeholder='Email'  />
            <input label="Password" type="password" required onChange={handleChange} name="password" value={password} placeholder='Password'/>
            <div className="forgot-password-holder">
            Forgot Password ?
            </div>
            <button  type="submit">Sign In</button>
            
        </form>
    </div> 
    )
}


export default SignInOrder;