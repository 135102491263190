import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { selectCategoriesMap } from "../../store/categories/category.selector";
import { Outlet, useParams , Route, Routes} from "react-router-dom";
import { selectProductsMap } from "../../store/categories/category.selector";
import {ReactComponent as RightArrowIcon} from '../../assets/right-arrow.svg';
import SoloProduct from "../../components/solo-product/solo-product.component";
import ProductCard from "../../components/product-card/product-card.component";
import PromotedItems from "../../components/promoted-items/promoted-items.component";
import { Link } from "react-router-dom";
import Footer from "../../components/footer/footer.component";
const SearchScreen = () => {
    const params = useParams();
    
    const productName = Object.values(params)
  
    const products = useSelector(selectProductsMap);
    const categories = useSelector(selectCategoriesMap);
     const [brand , setBrand] = useState('');
    const categoryProducts = products.filter((produc, idx) => produc.brand.startsWith(productName))
 
    const [showAll , setShowAll] = useState(3);
     const [showAllBrands , setShowAllBrands] = useState(false);
   
    const categoriesLength = categories.length;
    const handleShowAll = () => {
        if (showAll === 3){
         setShowAll(categoriesLength);
        } else {
               setShowAll(3);
        }
       
    }
       const handleShowAllBrands = () => {
       setShowAllBrands(!showAllBrands)
       
    }
    const handleBrandsHeets = () => {
        setBrand('');
    }
    return (
          <>
       
   <div className="category-screen-wrapper">
            
             <div className="filters-box">
                <h4>Categories</h4>
            {categories.slice(0,showAll).map((category) => (
                 <Link to={`../../categories/${category.name}`} >{category.name}<RightArrowIcon/>  </Link>
            ))}
            <button onClick={handleShowAll}>Show All</button>
                <h4>Brands</h4>
                <span >Marlboro</span>
                <span onClick={handleBrandsHeets}>Heets</span>
               
                {
                    showAllBrands ? <> <span>Toscano</span>  <span>Xikar</span> </> : null
                }
               
                   <button onClick={handleShowAllBrands}>Show All</button>
                   <div className="filter-buttons">
                    <button>Apply</button>
                    <button>Clear</button>
                   </div>
             </div>
             <div className="product-categories-wrapper">
          {categoryProducts.length > 0 ?  <h1>Searched for "{productName}" </h1> : null }
             <div className="products-box">
                  
                {categoryProducts.length > 0 ?
             categoryProducts.map((product) => (
                <ProductCard key={product.id} product={product} />
                
        )) : <>
        <h3>We can't seem to find any products that match your search for `{productName}`  <br /> Check out some of these popular products</h3>
        <PromotedItems/>
        </>}
         
              </div>
              </div>
        </div>
   
    <Footer/>
    <Outlet/>
                
      </>
    )
};

export default SearchScreen;