import { initializeApp } from 'firebase/app';
import { getAuth, signInWithPopup, GoogleAuthProvider, signInWithEmailAndPassword, createUserWithEmailAndPassword  , signOut , onAuthStateChanged,updateProfile, updatePhoneNumber} from 'firebase/auth'
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore'
import { getDatabase, ref, onValue, get , child,set,update, push, remove} from "firebase/database";
const firebaseConfig = {
    apiKey: "AIzaSyB7dFewRQevdOMLK3Uww84hpZ8VfzYtDMU",
    authDomain: "massoud-store.firebaseapp.com",
    databaseURL: "https://massoud-store-default-rtdb.firebaseio.com",
    projectId: "massoud-store",
    storageBucket: "massoud-store.appspot.com",
    messagingSenderId: "177194415662",
    appId: "1:177194415662:web:0d3c8b4266faae657d11bf",
    measurementId: "G-HPZF3TL4M7"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);


const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({
    prompt: "select_account"
});

  export const db = getDatabase();
// Fetch Categories
export const getCategories = async () => {
  
    const categoriesRef = ref(db, 'categories/');
   
    const snapshot = await get(categoriesRef);
   const data = snapshot.val();
  
    return data;
 
  };
  // Fetch Addresses
export const getAddresses = async () => {
   
    const categoriesRef = ref(db, 'addresses/');
   
    const snapshot = await get(categoriesRef);
   const data = snapshot.val();
 
    return data;
 
  };

// Fetch Products
export const getProducts = async () => {
    
    const categoriesRef = ref(db, 'products/');
   
    const snapshot = await get(categoriesRef);
   const data = snapshot.val();
  
    return data;
 
  };

  //Fetch users
export const getUsers = async () => {
    
    const categoriesRef = ref(db, 'users/');
   
    const snapshot = await get(categoriesRef);
   const data = snapshot.val();
  
    return data;
 
  };


  // Fetch Orders
export const getOrders = async () => {
    
    const categoriesRef = ref(db, 'orderss/');
   
    const snapshot = await get(categoriesRef);
   const data = snapshot.val();
  
    return data;
 
  };

// ADD ORDER

export const addOrder = async (id,userId, cartItems, cartTotal,formFields) => {
    const ordersParent = ref(db, 'orderss');
    const ordersChild = child( ordersParent, id);
 
   set(ordersChild , {
     id,
     userId,
     cartItems,
     cartTotal,
    formFields
   }); 
}

// ADD ADDRESS
export const addAddress = async (address, phoneNumber,streetAddress, title, uid,id) => {
    const addressParent = ref(db,'addresses/');
    const addressChild = child(addressParent,id);

    set(addressChild,{
        id,
        uid,
        title,
        address,
        
        phoneNumber,
        streetAddress
    })
}

// Update personal info

export const updatePersonalInfo = async (displayName,phoneNumber,id) => {
    const updateRef = ref(db,'users/');
    const updateRefChild = child(updateRef,id);
    update(updateRefChild, {
        displayName: displayName,
        phoneNumber:phoneNumber
    })
}

//Update Address
export const updateAddress = async (address, phoneNumber,streetAddress, title , id) => {
    const addressRef = ref(db,'addresses/');
    const addressRefChild = child(addressRef,id);
    update(addressRefChild,{
        address,
        phoneNumber,
        streetAddress,
        title
    })
}

//Delete Addres
export const deleteAddress = async (id) => {
    const addressRef = ref(db,'addresses');
    const addressRefChild = child(addressRef,id);
    remove(addressRefChild);
}


export const auth = getAuth();

export const signInWithGooglePopup = () => signInWithPopup(auth, googleProvider);




export const createUserDocumentFromAuth = async(userAuth, displayName, phoneNumber) => {

    if (!userAuth) return;
    const dbRef = ref(getDatabase());
    const userSnapshott = await get(child(dbRef,`users/${userAuth.uid}`));

    if(!userSnapshott.exists()){
        const usersData = ref(db,'users');
        const userData = child(usersData,userAuth.uid);
        const createdAt = Date();
        try {
            await set(userData,{
                createdAt,
                email: userAuth.email,
                displayName,
                phoneNumber,
                id: userAuth.uid
                
                
            })
        } catch (error) {
            console.log('error creating user file', error.message);
        }
    }
    /*const userDocRef = doc(db, 'users', userAuth.uid);
    const userSnapshot = await getDoc(userDocRef);

    if (!userSnapshot.exists()) {
        const { displayName, email, phoneNumber } = userAuth;
        const createdAt = new Date();

        try {
            await setDoc(userDocRef, {
                displayName,
                email,
                createdAt,
                phoneNumber,
               
            })
        } catch (error) {
            console.log('error creating user', error.message);
        }
    }*/
    return userSnapshott;
}

export const createAuthUserWithEmailAndPassword = async(email, password) => {
    if (!email || !password) return;

     return await createUserWithEmailAndPassword(auth, email, password);
     
       
}
/*export const updateUserProfile = async (displayName, phoneNumber) => {
     await   updateProfile(auth.currentUser,{
                displayName:displayName,
                
            })
            updatePhoneNumber(auth.currentUser,{
                phoneNumber:phoneNumber
            })
}*/

export const signInAuthUserWithEmailAndPassword = async(email, password) => {
    if (!email || !password) return;

    return await signInWithEmailAndPassword(auth, email, password);
       
    
}

export const signOutUser = async () => await signOut(auth);


export const onAuthStateChangedListener = (callback) => onAuthStateChanged(auth, callback)