

import Directory from '../../components/directory/directory.component';
import PromotedItems from '../../components/promoted-items/promoted-items.component';
import DownloadApp from '../../components/download-app/download-app.component';
import SignUpOffer from '../../components/sign-up-offer/sign-up-offer.component';
import Footer from '../../components/footer/footer.component';
import { useEffect } from 'react';
import { setUsersMap } from '../../store/categories/category.action';
import { useDispatch } from 'react-redux';
import { getUsers } from '../../utils/firebase/firebase.utils';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation ,Pagination} from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import './home.styles.scss'
const Home = () => {
   const dispatch = useDispatch();
  useEffect(() => {
    const getUsersMap = async () => {
      const categoriesArrayy = await getUsers();
      dispatch(setUsersMap(categoriesArrayy));
   
    };
    getUsersMap();
  },[])
  return (
    <>
     <Swiper  pagination={{
          clickable: true,
        }} loop={true} navigation={true} modules={[Pagination,Navigation]} className="mySwiper">
          <SwiperSlide style={{backgroundColor: "white"}}><img src={require('../../assets/macallan.jpg')} alt="" srcset="" /></SwiperSlide>
        <SwiperSlide style={{backgroundColor: "black"}}><img src={require('../../assets/home-banner.jpg')} alt="" srcset="" /></SwiperSlide>
        <SwiperSlide style={{backgroundColor: "#efefef"}}><img src={require('../../assets/HeetsVentaliaGR.jpg')} alt="" srcset="" /></SwiperSlide>
         <SwiperSlide style={{backgroundColor: "white"}}><img src={require('../../assets/xikar.jpg')} alt="" srcset="" /></SwiperSlide>
        
      </Swiper>
    <Directory/>
    <div className='promoted-sec' >
     <PromotedItems/>
    </div>
   
    <DownloadApp/>
    <SignUpOffer/>
    <Footer/>
    </>
  );
};

export default Home;
