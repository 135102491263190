import { useSelector } from 'react-redux';
import { useContext, useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { CartContext } from '../../contexts/cart.context';
import { selectCurrentUser } from '../../store/user/user.selector';
import { selectAddressesMap } from '../../store/categories/category.selector';
import Footer from '../footer/footer.component';
import { addOrder } from '../../utils/firebase/firebase.utils';

import { v4 } from 'uuid';
import { getAddresses, getOrders } from '../../utils/firebase/firebase.utils';
import { useDispatch } from 'react-redux';
import { setAddressesMap } from '../../store/categories/category.action';
import { setOrdersMap } from '../../store/categories/category.action';
import { useNavigate } from 'react-router-dom';
import { selectUsersMap } from '../../store/categories/category.selector';
import {ReactComponent as OrderComplete} from '../../assets/order-complete.svg';
import {ReactComponent as CloseIcon} from '../../assets/close.svg';
import './checkouts.styles.scss';
const defaultFormFields = {
    displayName:'',
    
    emailAddress:'',
    address:'',
    streetAddress:'',
    phoneNumber:'',
    
}
const Checkouts = () => {
    const [formFields, setFormFields] = useState(defaultFormFields);
    const [ordering,setOrdering] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userInfo = useSelector(selectUsersMap);
    const user = useSelector(selectCurrentUser);
    const addresses = useSelector(selectAddressesMap);
    const {cartItems, cartTotal , clearCartItems} = useContext(CartContext);
    let userPersonalInfo;
    if(user){
    userPersonalInfo = userInfo.filter((produc, idx) => produc.id === user.uid);
   } else {
    userPersonalInfo = undefined
   }
   const getOrdersMap = async () => {
    const orderArray = await getOrders();
    dispatch(setOrdersMap(orderArray))
   }
    useEffect(() => {
   const getAddressesMap = async () => {
      const categoriesArray = await getAddresses();
      dispatch(setAddressesMap(categoriesArray));
    };
 getAddressesMap();
  },[]);
     const handleChange = (event) => {
        const {name , value} = event.target;
        setFormFields({...formFields, [name]: value})
    }
    const [orderId, SetOrderId] = useState('');
    const addOrderHandler = async () => {
        const id = v4();
        
        let userId;

        if(!user){
             userId = v4()
        }else {
            userId = user.uid
            formFields.displayName = userPersonalInfo[0].displayName;
            
        }
      await  addOrder(id,userId, cartItems, cartTotal,formFields);
      await getOrdersMap();
      await SetOrderId(id);
      await clearCartItems();
      setOrdering(!ordering);
       
    }
    const handleNavOrder = () => {
        navigate('../')
    }
    
    return (
        <>
        {ordering ? <div className='order-received'>
            <div>
            <CloseIcon onClick={handleNavOrder} className='close-order'/>
            <OrderComplete/>
            <h2>Your order has <br /> been placed</h2>
            <p>Your order is successful. <br /> Please check order process in the app.</p>
            <button onClick={()=>navigate(`../orders/${orderId}`)}>Order details</button> 
            </div>
            </div> : <div></div> }
        <div className="checkouts-wrapper">
           
            <div className="addresses">
                <h3>Where do you want to receive your order ?</h3>
            { !user ? 
            <div className='guest-box'>
                
                <p>Please create an order address to order completion</p>
                <form action="">
                <input type="text" placeholder='First Name' onChange={handleChange} name="firstName" value={formFields.displayName} />
                
                <input type="email" placeholder='Emaill Address' onChange={handleChange} name="emailAddress" value={formFields.emailAddress}/>
                <input type="text" placeholder='Street address' onChange={handleChange} name="address" value={formFields.address} />
                <input type="text" placeholder='Apt, Floor, Unit' onChange={handleChange} name="streetAddress" value={formFields.streetAddress}/>
                <input type="number" placeholder='Phone Number' onChange={handleChange} name="phoneNumber" value={formFields.phoneNumber} />
                </form> 

            </div> 
            
            : <div> 
                <div>Choose where to deliver</div> 
                {
                     addresses.filter((produc, idx) => produc.uid === user.uid).map((item) => (
                    <div className='address-info-holder' onClick={() => setFormFields({displayName:userPersonalInfo.displayName, address:item.address,streetAddress:item.streetAddress,phoneNumber:item.phoneNumber})}>
                        <div>
                             <input type="radio" id={item.title} name="Address" value={item.address}/>
                             <label for={item.title}> {item.title} | {item.address}</label>
                            <button onClick={() => navigate(`../../edit-address/${item.id}`)}>Edit</button>
                        </div>
                 
                    
                    </div>
                    
                ))
                }
               
            </div>}
            { user ? <Link to="/add-address">Add New Address</Link> : null}
             
            </div>
        <div className='order-summary-box'>
                <h4>Payment Method</h4>
                <div className='payment-box'>
                   
                  
                    <p>Cash on delivery</p>
                </div>
                <div className='total-order-box'>
                    <p>Shipping</p>
                     
                    <h2>5$</h2>
                </div>
                <div className='total-order-box' >
                   
                     <input type="text" placeholder='Gift card or discount code' />
                     <button>Apply </button>
                    
                </div>
                <div className='total-order-box'>
                    <p>Total</p>
                     
                    <h2>{cartTotal}$</h2>
                </div>
                <button onClick={addOrderHandler}>Order Now</button>
            </div>
        </div>
       
        
        <Footer/>
            
        </>
    )
}


export default Checkouts;