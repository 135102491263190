import './soloi-product.styles.scss';
import { useParams} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectProductsMap } from '../../store/categories/category.selector';
import Footer from '../footer/footer.component';
import PromotedItems from '../promoted-items/promoted-items.component';
import SignUpOffer from '../sign-up-offer/sign-up-offer.component';
import { useContext,useEffect } from 'react';
import { CartContext } from '../../contexts/cart.context';
const SoloProduct = () => {
     const {id} = useParams();
    const products = useSelector(selectProductsMap);
    const product = products.filter((item) => item.id === id);
    const { name , description, price , brand , imageUrl} = product[0];
    const backgroundImage = {
    backgroundImage: `url(${imageUrl})`
    }
    const {addItemToCart,setIsCartOpen} = useContext(CartContext);
    const addProductToCart = async () => {
     await addItemToCart(product[0]);
     setIsCartOpen(true);
    };
    useEffect(() => {
  window.scrollTo(0, 0)
}, [id])
    return (
        <div className='solo-product-page-wrapper' id='productt'>

         <div className='solo-product-wrapper'>
           <div className='product-image-bg' style={backgroundImage}>
         
           </div>
           <div className='product-details-box'>
            <h1>{name}</h1>
            <h4>{brand}</h4>
            <h2>{price}$</h2>
            <p>{description}</p>
            <button onClick={addProductToCart}>Add To Cart</button>
           </div>
        </div>
        <div className='prom-items'>
        <PromotedItems/>
        </div>
      
        <SignUpOffer/>
        <Footer/>
        </div>
       
    )
}


export default SoloProduct;