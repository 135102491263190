import React from "react";
import CategoryItem from "../category-item/category-item.component";
import './directory.styles.scss';

import CategoryBox from "../category-box/category-box.component";
const Directory = ({categories}) => {
     
   
    return (
        <div className="directory-container">
            
            <CategoryBox/>
       </div>
    )
}

export default Directory;