import { useState , useContext  } from "react";
import { useNavigate } from "react-router-dom";
import {createAuthUserWithEmailAndPassword, createUserDocumentFromAuth,signInAuthUserWithEmailAndPassword } from '../../utils/firebase/firebase.utils';
import FormInput from "../form-input/form-input.component";

import { setCurrentUser } from "../../store/user/user.action";
import { useDispatch } from "react-redux";
import './sign-in-form.styles.scss'
const defaultFormFields = {
   
    email:'',
    password:"",
    
}

const SignInForm = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
    const [formFields, setFormFields] = useState(defaultFormFields)
    const {email,password} = formFields;
    


    const resetFormFields = () => {
        setFormFields(defaultFormFields);
      };
const handleSubmit = async (event) => {
    event.preventDefault();
    
    try {
       
        const {user} = await signInAuthUserWithEmailAndPassword(email,password);
        
        resetFormFields();
        dispatch(setCurrentUser(user)); 
        navigate("../../", { replace: true });
        
    } catch(error) {
        if(error.code === "auth/user-not-found"){alert("user not found")}
        if(error.code === "auth/wrong-password"){alert("wrong password")}
        console.log('user sign in error', error)
    }
   
   
}

    const handleChange = (event) => {
        const {name , value} = event.target;
        setFormFields({...formFields, [name]: value})
    }

    return (
    <div className="sign-in-wrapper">
        
        <form onSubmit={handleSubmit}>
            

           
            

            <input  label="Email" type="email" required onChange={handleChange} name="email" value={email} placeholder='Email'  />
            <input label="Password" type="password" required onChange={handleChange} name="password" value={password} placeholder='Password'/>
            <div className="forgot-password-holder">
            Forgot Password ?
            </div>
            <button  type="submit">Sign in</button>
            
        </form>
    </div>       
    )
}

export default SignInForm;