import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../store/user/user.selector";
import { selectUsersMap } from "../../store/categories/category.selector";
import { signOutUser } from "../../utils/firebase/firebase.utils";
import { useNavigate } from "react-router-dom";
import PersonalInfo from "../personal-info/personal-info.component";
import Addresses from "../addresses/addresses.component";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {ReactComponent as CloseIcon} from '../../assets/close.svg';
import { useState } from "react";
import './profile.styles.scss';
const Profile = ({clicking}) => {
    const [tabIndex, setTabIndex] = useState(0);
    const current = useSelector(selectCurrentUser);
    
    const userInfo = useSelector(selectUsersMap);
    
    let userPersonalInfo;
    if(current){
    userPersonalInfo = userInfo.filter((produc, idx) => produc.id === current.uid);
   } else {
    userPersonalInfo = undefined
   }
    const navigate = useNavigate();
    const handleLogOut = async () => {
        await signOutUser();
        await clicking()
        navigate('../');

    }
    return (
        <div className="profile-wrapper">
            <CloseIcon className="close-profile-btn" onClick={clicking}/>
           
            <h1>{current ? userPersonalInfo[0].displayName : null}</h1>
            
             <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
      <TabList>
        <Tab>Account</Tab>
      
        <Tab>Addresses</Tab>
    
      </TabList>
      <TabPanel> <PersonalInfo clicking={clicking}/></TabPanel>
    
       <TabPanel><Addresses/></TabPanel>
    </Tabs>
            
           
           

        </div>
    )
}


export default Profile;