

  export const selectCategoriesMap = (state) => {
    const data = state.categories.categoriesMap
    var newArrayDataOfOjbect = Object.keys(data).map(key => {
     
    return data[key];
})
  return newArrayDataOfOjbect

};
  export const selectProductsMap = (state) => {
    const data = state.categories.productsMap
    var newArrayDataOfOjbect = Object.keys(data).map(key => {
     
    return data[key];
})
return newArrayDataOfOjbect

};
  export const selectAddressesMap = (state) => {
    const data = state.categories.addressesMap;
    var newArrayDataOfOjbect = Object.keys(data).map(key => {
     
    return data[key];
})
 return newArrayDataOfOjbect

};

  export const selectOrdersMap = (state) => {
    const data = state.categories.ordersMap;
    var newArrayDataOfOjbect = Object.keys(data).map(key => {
     
    return data[key];
})
 return newArrayDataOfOjbect

};
export const selectUsersMap = (state) => {
    const data = state.categories.usersMap;
    var newArrayDataOfOjbect = Object.keys(data).map(key => {
     
    return data[key];
})
 return newArrayDataOfOjbect

};
 