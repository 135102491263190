import { Routes, Route } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Home from './routes/home/home.component';
import Navigation from './routes/navigation/navigation.component';
import Authentication from './routes/authentication/authentication.component';
import AddAddress from './routes/order-address/add-address.component';
import EditAddress from './routes/order-address/edit-address.component';
import Checkouts from './components/checkouts/checkout.component';
import Orders from './routes/orders/orders.component';
import OrderTracking from './components/order-tracking/order-tracking.component';
import Order from './components/order/order.component';
import CategoriesScreen from './routes/categories/categories.component';
import SoloProduct from './components/solo-product/solo-product.component';
import SearchScreen from './routes/search/search-screen.component';
import OrderAuth from './routes/order-auth/order-auth.component';
import Shop from './routes/shop/shop.component';
import {setCurrentUser } from './store/user/user.action';
import {useDispatch} from 'react-redux';
import {getCategories, getProducts,getAddresses,getUsers } from './utils/firebase/firebase.utils';
import {setCategoriesMap, setProductsMap , setAddressesMap ,setUsersMap} from './store/categories/category.action';
import './App.scss'
import { onAuthStateChangedListener, createUserDocumentFromAuth} from './utils/firebase/firebase.utils';

const App = () => {
const [age,setAge] = useState(true);

  const dispatch = useDispatch();
    useEffect(() => {
        const getCategoriesMap = async () => {
      const categoriesArray = await getCategories();
      dispatch(setCategoriesMap(categoriesArray));
    };

     const getAddressesMap = async () => {
      const categoriesArray = await getAddresses();
      dispatch(setAddressesMap(categoriesArray));
    };
    
      const getProductsMap = async () => {
      const categoriesArrayy = await getProducts();
      dispatch(setProductsMap(categoriesArrayy));
    };
     const getUsersMap = async () => {
      const categoriesArrayy = await getUsers();
      dispatch(setUsersMap(categoriesArrayy));
   
    };
    

    getCategoriesMap();
    getProductsMap();
    getAddressesMap();
    getUsersMap();
    
    },[]);
        


    useEffect(() => {
          
     const unsubscribe = onAuthStateChangedListener((user)=>{
          if(user){
              createUserDocumentFromAuth(user);
          }
          
          dispatch(setCurrentUser(user));
      })
      return unsubscribe;
  },[]);
 
    return ( 
      <>
      {
        age ? <div className='age-wrapper'>
      <div className='age-box'>
       <img src={require('./assets/logo-white.png')} alt="" srcSet="" />
       <p>You must verify that you are over 18 years old to enter this site.</p>
       <button onClick={() => {setAge(!age)}}>I'M 18+</button>
      </div>

      </div> : <></>
      }
      
    <Routes>
        <Route path = '/' element = {<Navigation/>}>
          {/* <Route index element = { <Welcome/>}/> */}
          <Route index  element = { <Home/>}/> 
          <Route path="auth/*" element = {<Authentication/>}/>
          <Route path="auth-order/*" element = {<OrderAuth/>}/> 
          <Route path="shop" element = {<Shop/>}/>
          <Route path="orders/*">
            <Route index  element={<Orders/>}/>
            <Route path=':id' element={<Order/>} />
             <Route path='track/*' >
                 <Route index path=':orderId' element={<OrderTracking/>} />
              </Route>
          </Route>
          
          <Route path="products/*">
          <Route index path=":id" element={<SoloProduct/>}/>
          </Route>

          <Route path="search/*" element={<SearchScreen/>} />
          <Route path="categories/*" element = {<CategoriesScreen/>}/>
          <Route path="checkout" element = {<Checkouts/>}/> 
      
          <Route path="add-address" element = {<AddAddress/>}/> 
          <Route path="edit-address/:addressId" element = {<EditAddress/>}/> 
        </Route>
    </Routes>
</>
    );
};

export default App;